body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Responsive Styling */
@media only screen and (max-width: 600px) {
  /* Add your styles for mobile devices here */
h2 {
  font-size: 12px; /* Adjust the size according to your preference */
}

h4 {
  font-size: 5px;
}

  body {
    font-size: 14px;
  }

  /* Additional styles for smaller screens */
}

/* You can add more media queries for different screen sizes if needed */
